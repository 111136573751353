<i18n>
ru:
  cart: Корзина
  checkout: Оформить заказ
  delete: Удалить товар
  menuLink: Перейти в меню
  numberInCart: 'Товаров в корзине: '
  hoverAmount: 'Количество:'
  hoverSum: 'Сумма заказа:'
  orderAmountTotal: 'Итого:'
  toCart: В корзину
ua:
  cart: Кошик
  checkout: Оформити замовлення
  delete: Видалити товар
  menuLink: Перейти до меню
  numberInCart: 'Товарів у кошику: '
  hoverAmount: 'Кількість:'
  hoverSum: 'Сума замовлення:'
  orderAmountTotal: 'Всього:'
  toCart: У кошик
us:
  cart: Cart
  checkout: Checkout
  delete: Delete item
  menuLink: Go to menu
  numberInCart: 'Items in the cart: '
  hoverAmount: 'Amount:'
  hoverSum: 'Order sum:'
  orderAmountTotal: 'Total:'
  toCart: In the cart
</i18n>

<template>
  <template v-if="loaded">
    <div class="v-small-basket-wrapper">
      <common-popover-menu
        class="v-small-basket"
        :disabled="!!isSmall || !appConfig.VueSettingsPreRun.SmallBasketShowOnHover"
        :show-on-click="false"
        placement="bottom-end"
        theme="small-cart"
      >
        <template #hoverable>
          <div
            class="v-small-basket--button v-mb-sm v-d-flex v-align-items-center v-pointer"
            :class="{
              'v-small-basket--button__barad-nimras v-justify-content-center':
                appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradNimras',
              'v-small-basket--button__hollow':
                appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradNimras' &&
                appConfig.VueSettingsPreRun.SmallBasketShowMode === 'default'
            }"
            :style="
              appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradNimras'
                ? 'flex-direction: column'
                : ''
            "
            :data-test-id="smallBasketDataTestId"
            @click="cartButtonClick"
          >
            <div
              :class="[
                appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradNimras'
                  ? ''
                  : 'v-small-basket--button--left'
              ]"
            >
              <span
                v-if="
                  appConfig.VueSettingsPreRun.SmallBasketShowMode === 'total-amount' &&
                  appConfig.VueSettingsPreRun.MenuGroupsLayout !== 'BaradNimras'
                "
                v-html="translate('smallBasket.cart')"
              />
              <common-badge
                v-else-if="
                  appConfig.VueSettingsPreRun.SmallBasketShowMode === 'default' ||
                  (appConfig.VueSettingsPreRun.SmallBasketShowMode === 'total-amount' &&
                    appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradNimras')
                "
                :invert-colors="
                  appConfig.VueSettingsPreRun.MenuGroupsLayout !== 'BaradNimras' ||
                  appConfig.VueSettingsPreRun.SmallBasketShowMode !== 'default'
                "
                :content="content.length"
              >
                <icon-menu-basket class="v-small-basket--button--icon" />
              </common-badge>
              <icon-menu-basket
                v-else
                class="v-small-basket--button--icon"
              />
            </div>
            <div
              v-if="
                appConfig.VueSettingsPreRun.SmallBasketShowMode === 'default' ||
                appConfig.VueSettingsPreRun.SmallBasketShowMode === 'money'
              "
              class="v-align-currency-center v-ml-xs v-w-100"
            >
              <common-currency
                v-if="toPayWithMoney === 0 && toPayWithPoints > 0"
                show-points
                :amount="toPayWithPoints"
              />
              <common-currency
                v-else
                :amount="toPayWithMoney"
              />
            </div>
            <span
              v-else-if="appConfig.VueSettingsPreRun.MenuGroupsLayout !== 'BaradNimras'"
              class="v-ml-sm"
              v-html="content.length"
            />
          </div>
        </template>
        <template #content>
          <div class="v-small-basket-hover">
            <div class="v-d-flex v-justify-content-between v-mb-sm">
              <div class="v-small-basket-hover--title v-font-weight-600">
                <span
                  class="v-mr-xxs"
                  v-html="translate('smallBasket.numberInCart')"
                />
                <span
                  class="v-body-text-color-light"
                  v-html="totalAmount"
                />
              </div>
              <div
                v-if="items.length > 0"
                class="v-pointer"
                @click="clearCart"
              >
                <icon-general-trashcan class="v-small-basket-hover--icon" />
              </div>
            </div>

            <div class="v-mb-md v-small-basket-hover--items">
              <div class="v-mb-sm">
                <cart-items-small />
              </div>
              <div
                v-if="appConfig.VueSettingsPreRun.CartExtraShowInSmallBasket"
                class="v-mb-sm"
              >
                <cart-extra-products :max-items-override="1.1" />
              </div>

              <cart-promocode
                v-if="items.length > 0"
                in-small-cart
              />
            </div>

            <div
              v-if="toPayWithMoney > 0 || toPayWithPoints > 0"
              class="v-d-flex v-justify-content-between v-align-items-baseline"
            >
              <div class="v-small-basket-hover--total v-align-currency-center">
                <span
                  class="v-mr-xxs"
                  v-html="translate('smallBasket.orderAmountTotal')"
                />
                <common-currency
                  v-if="toPayWithMoney === 0 && toPayWithPoints > 0"
                  class="v-font-weight-600"
                  show-points
                  :amount="toPayWithPoints"
                />
                <common-currency
                  v-else
                  class="v-font-weight-600"
                  :amount="toPayWithMoney"
                />
              </div>
              <arora-button
                class-name="v-small-basket-hover--button"
                :label="translate('smallBasket.toCart')"
                @click="toCart"
              />
            </div>
            <div
              v-else
              class="v-d-flex v-justify-content-end v-align-items-baseline v-py-xs"
            >
              <arora-button
                :label="translate('smallBasket.menuLink')"
                @click="navigateToMenu"
              />
            </div>
          </div>
        </template>
      </common-popover-menu>
    </div>
    <!--workaround for overflowing z-index -->
    <!-- TODO: [after SSR]: rework after moving all to vue -->
    <Teleport to="#__nuxt">
      <div
        v-if="panelOpen"
        class="v-slide-menu-backdrop"
        @click="slideSmallBasketOff()"
      />
      <transition
        appear
        name="v-slide"
      >
        <div
          v-if="panelOpen"
          class="v-slide-small-basket v-scrollbar"
          data-test-id="slide-small-basket"
        >
          <div class="v-slide-small-basket-top v-d-flex v-align-items-center v-justify-content-between">
            <span
              class="v-slide-small-basket-title"
              v-html="translate('smallBasket.cart')"
            />
            <arora-button
              class-name="v-btn-border"
              :label="translate('smallBasket.delete')"
              @click="slideSmallBasketOff"
            >
              <icon-general-cross />
            </arora-button>
          </div>

          <cart-items-small />

          <div
            v-if="appConfig.VueSettingsPreRun.CartExtraShowInSmallBasket"
            class="v-slide-small-basket-extra"
          >
            <cart-extra-products :max-items-override="1" />
          </div>

          <div class="v-slide-small-basket-bottom">
            <cart-promocode in-small-cart />

            <arora-button
              class-name="v-submit-button v-btn-lg v-w-100 v-align-currency-center"
              :label="translate('smallBasket.checkout')"
              data-test-id="slide-small-basket-order-confirm"
              @click="toCart"
            >
              <span v-html="translate('smallBasket.checkout')" />
              <common-currency
                v-if="toPayWithMoney === 0 && toPayWithPoints > 0"
                show-points
                :amount="toPayWithPoints"
              />
              <common-currency
                v-else
                :amount="toPayWithMoney"
              />
            </arora-button>
          </div>
        </div>
      </transition>
    </Teleport>
  </template>
  <common-skeleton v-else />
</template>

<script setup lang="ts">
import { PositionType } from '~api/consts'

import type { CartItem } from '~types/clientStore'

import { useWindowSize } from '@arora/common'

const { translate } = useI18nSanitized()

const { isSmall } = useWindowSize()
const { items } = useCartItems()
const restaurantStore = useRestaurantStore()
const clientStore = useClientStore()
const appConfig = useAppConfig()

onMounted(() => {
  clientStore.initClientState()
})

const panelOpen = ref<boolean>(false)

const loaded = computed<boolean>(() => {
  return clientStore.ClientState?.data !== null
})

const toPayWithMoney = computed<number>(
  () => clientStore.ClientState?.data?.Cart?.ToPayWithMoneyActive?.WithDiscount ?? 0
)

const toPayWithPoints = computed<number>(
  () => clientStore.ClientState?.data?.Cart?.ToPayWithPoints?.WithDiscount ?? 0
)

const content = computed<CartItem[]>(() => {
  const result: CartItem[] | undefined = clientStore.ClientState?.data?.Cart?.Content

  if (!result) return []

  return result.filter((ci: CartItem) => ci.PositionType !== PositionType.Additional)
})

const smallBasketDataTestId = computed<string>(() => {
  return `small-basket-button${appConfig.RestaurantSettingsPreRun.OneStageCart ? '-one-stage' : '-two-stage'}`
})

const totalAmount = computed<number>(() => {
  if (content.value.length === 0) return 0

  return content.value
    .map((m) => m.Count)
    .reduce((item1, item2) => {
      return item1 + item2
    })
})

function clearCart(): void {
  clientStore.removeProductsFromCart(false)
}

function cartButtonClick(): void {
  if (isSmall.value) {
    navigateTo(appConfig.VueSettingsPreRun.Links.CartFirstStep)

    return
  }

  if (appConfig.VueSettingsPreRun.SmallBasketShowOnHover) toCart()
  else slideSmallBasketOn()
}

function toCart(): void {
  slideSmallBasketOff()
  navigateTo(appConfig.VueSettingsPreRun.Links.CartFirstStep)
}

function slideSmallBasketOn(): void {
  panelOpen.value = true
  document.body.classList.add('v-open-overflow')
}

function slideSmallBasketOff(): void {
  panelOpen.value = false
  document.body.classList.remove('v-open-overflow')
}

function navigateToMenu(): void {
  navigateTo(appConfig.VueSettingsPreRun.Links.MenuLink)
}

let once = true

watch(
  () => panelOpen.value,
  (newValue) => {
    if (newValue && once) {
      once = false

      restaurantStore.Metrics?.sendMetricCartView(true)
    }
  }
)
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-small-basket-wrapper {
  height: 100%;
  padding: 10px 0 0.5rem 0.5rem;
  width: fit-content;
}

.v-small-basket {
  &--button {
    @include mixins.trans;
    padding: 0.5rem 1.1rem;

    background-color: variables.$SmallBasketBackgroundColor;
    color: variables.$SmallBasketColor;

    border-radius: variables.$BorderRadiusInput;

    min-height: 42px;
    width: fit-content;
    min-width: 65%;

    margin: auto 0 auto auto;

    &:hover {
      background: variables.$SmallBasketBackgroundColorVariant;
      opacity: 0.75;
    }

    &__hollow {
      background-color: variables.$HeaderBackgroundColor;
      color: variables.$SmallBasketColor;
      border: 3px solid variables.$SmallBasketBackgroundColor;
      width: fit-content;

      &:hover {
        background: variables.$HeaderBackgroundColor;
        border-color: variables.$SmallBasketBackgroundColorVariant;
      }

      .v-badge-root > .v-badge {
        font-weight: 600;
        border-width: 3px;
      }

      .v-small-basket--button--icon {
        path {
          stroke: variables.$SmallBasketBackgroundColor;
        }
      }

      .v-align-currency-center {
        color: variables.$SmallBasketBackgroundColor;
      }
    }

    &__barad-nimras {
      height: 80px;
      width: 80px;
      min-width: fit-content;
      padding: 0.5rem;

      .v-small-basket--button--icon {
        width: 40px;
        height: 40px;
      }

      .v-align-currency-center {
        font-size: 1.25rem;
        font-weight: 600;
      }
    }

    &--left {
      border-right: 1.5px solid variables.$PrimaryColorOpaq50;
      padding-right: 1rem;
    }

    &--icon {
      width: 24px;
      height: 24px;
      fill: none;

      path {
        stroke: variables.$SmallBasketColor;
      }
    }
  }
}

.v-small-basket-hover {
  padding: 28px;
  width: 440px;

  &--total {
    font-size: 1.25rem;
  }

  &--title {
    font-size: 1.1rem;
  }

  &--items {
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(125, 125, 125, 0.1);
  }

  &--button {
    width: 145px;
  }

  &--icon {
    @include mixins.trans;

    height: 24px;
    width: 24px;

    fill: none;

    path {
      stroke: variables.$BodyTextColorLight;
    }

    &:hover {
      path {
        stroke: variables.$PrimaryBackgroundColor;
      }
    }
  }
}

.v-hover-show-promo {
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.v-slide-small-basket {
  background: variables.$BodyElementsBackground;
  color: variables.$BodyTextColor;
  max-width: 440px;
  min-width: 440px;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  padding: 10px;
  height: 100vh;
  box-shadow: variables.$FloatShadow;

  @include mixins.trans;

  overflow-y: auto;

  .v-slide-small-basket-top {
    padding: 1rem;
    margin-bottom: 1rem;

    .v-slide-small-basket-title {
      font-size: variables.$TextSizeH1;
    }

    .v-btn-border {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      padding: 0;
      opacity: 0.7;
      display: flex;
      align-items: center;
      justify-content: center;
      color: variables.$BodyTextColorLight;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .v-slide-small-basket-extra {
    padding: 10px 0 10px 15px;

    .v-extra-product-card {
      max-height: 130px;
      padding: 10px;
    }

    .shop-counter {
      max-height: 25px;
    }

    .v-extra-product-title {
      margin-bottom: 5px;
    }

    .v-extra-product-counter {
      margin-bottom: 5px;
    }
  }

  .v-slide-small-basket-bottom {
    padding: 15px;

    .promocode-container {
      max-width: 100%;
      width: 100%;
    }
  }
}

/* before the element is shown, start off the screen to the right */
.v-slide-enter-active,
.v-slide-leave-active {
  right: -100%;
}
</style>
