<template>
  <svg
    fill="none"
    height="21"
    viewBox="0 0 26 21"
    width="26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1L5.5 7.5"
      stroke="white"
      stroke-linecap="round"
      stroke-width="2"
    />
    <path
      d="M16 1L20.5 7.5"
      stroke="white"
      stroke-linecap="round"
      stroke-width="2"
    />
    <path
      d="M9 12V15"
      stroke="white"
      stroke-linecap="round"
      stroke-width="2"
    />
    <path
      d="M13 12V15"
      stroke="white"
      stroke-linecap="round"
      stroke-width="2"
    />
    <path
      d="M17 12V15"
      stroke="white"
      stroke-linecap="round"
      stroke-width="2"
    />
    <path
      d="M18.1465 20L7.8535 20C6.95509 20 6.16692 19.4009 5.92647 18.5353L3 8L23 8L20.0735 18.5353C19.8331 19.4009 19.0449 20 18.1465 20Z"
      stroke="white"
      stroke-linecap="round"
      stroke-width="2"
    />
    <path
      d="M1 8H25"
      stroke="white"
      stroke-linecap="round"
      stroke-width="2"
    />
  </svg>
</template>
